import React from "react"
import { useStaticQuery, graphql, PageProps } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, Stars } from "../components/section"
import { QueryPagesProps } from "../types/interface"

const Oferta: React.FC<PageProps> = () => {
  const data: QueryPagesProps = useStaticQuery(graphql`
    query {
      gcms {
        sections(where: { whichSite_in: oferta }) {
          richText {
            html
          }
          title
          text
          isStars
          index
          id
          imageUrl
          buttonText
          buttonUrl
          darkMode
          standardType
        }
        seoDetails(where: { witchSite_in: oferta }) {
          siteTitle
          description
        }
      }
    }
  `)

  const sectionsData = data.gcms.sections.sort(function (a, b) {
    return a.index - b.index
  })

  const { siteTitle, description } = data.gcms.seoDetails[0]

  return (
    <Layout>
      <SEO title={siteTitle} description={description} />
      {sectionsData.map(
        ({
          title,
          text,
          buttonText,
          buttonUrl,
          darkMode,
          imageUrl,
          id,
          isStars,
          standardType,
          richText,
        }) => (
          <Section
            key={id}
            id={id}
            title={title}
            text={text}
            richText={richText && richText.html}
            buttonText={buttonText}
            buttonUrl={buttonUrl}
            darkBackground={darkMode}
            imgUrl={imageUrl}
            sliderCategory={standardType}
          >
            {isStars && <Stars />}
          </Section>
        )
      )}
    </Layout>
  )
}

export default Oferta
